import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  InputBase,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Search } from "lucide-react";
import logo from "../images/Logo For Website 01.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/actions/loginActions";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const user = useSelector((state) => state.user);
  const isLoggedIn = user.isLoggedIn;
  const loginMethod = user.loginMethod;

  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  // Safe destructuring with default values
  const userData = loginMethod === "google" ? user.googleUser : user.customUser;
  const firstName = userData?.firstName || '';
  const lastName = userData?.lastName || '';
  const email = userData?.email || '';

  const handleSignInButton = () => {
    navigate("/login");
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("serviceToken");
    dispatch(logoutUser());
    handleMenuClose();
  };

  const handleProfile = () => {
    navigate("/profile");
    handleMenuClose();
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: 999,
          backgroundColor: "#ffffff",
          width: { xs: "100%", md: "100%" },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#ffffff",
            padding: "8px 16px",
            borderBottom: "1px solid #00000014",
            minHeight: { xs: "64px", sm: "64px" },
          }}
        >
          {/* Left Side - Logo and Search */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              gap: 4,
            }}
          >
            {/* Logo with slight right margin */}
            <Box
              onClick={handleLogoClick}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: { xs: "0", sm: "19px" },
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: isMobile ? "120px" : "175px",
                  maxWidth: "100%",
                  marginRight: "4px",
                }}
              />
              <Typography
                sx={{
                  fontSize: isMobile ? "10px" : "12px",
                  color: "#50C878",
                  fontWeight: 600,
                  backgroundColor: "rgba(80, 200, 120, 0.1)",
                  padding: "2px 6px",
                  borderRadius: "4px",
                  marginLeft: "4px",
                  letterSpacing: "0.5px",
                }}
              >
                BETA
              </Typography>
            </Box>

            {/* Search Bar */}
            {!isMobile && (
              <InputBase
                placeholder="Search…"
                sx={{
                  width: "250px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "0px 8px",
                }}
              />
            )}
          </Box>

          {/* Right Side - Search Icon (Mobile) and Avatar/Sign In */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {isMobile && (
              <IconButton
                color="inherit"
                onClick={toggleSearch}
                sx={{ color: "#0F172A" }}
              >
                <Search />
              </IconButton>
            )}

            {isLoggedIn ? (
              <Avatar
                sx={{ bgcolor: "#0F172A", cursor: "pointer" }}
                onClick={handleAvatarClick}
              >
                {firstName && lastName ? `${firstName[0]}${lastName[0]}`.toUpperCase() : ''}
              </Avatar>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSignInButton}
                style={{ backgroundColor: "#0F172A", textTransform: "none" }}
              >
                Sign In
              </Button>
            )}
          </Box>

          {/* Mobile Search Bar */}
          {isMobile && showSearch && (
            <Box
              sx={{
                position: "absolute",
                top: "100%",
                left: 0,
                right: 0,
                padding: "8px 16px",
                backgroundColor: "#fff",
                borderBottom: "1px solid #00000014",
                zIndex: 1000,
              }}
            >
              <InputBase
                placeholder="Search…"
                fullWidth
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "0px 8px",
                }}
                autoFocus
              />
            </Box>
          )}

          {/* User Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {firstName} {lastName}
                </Typography>
                <Typography variant="body2">{email}</Typography>
              </Box>
            </MenuItem>
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box sx={{ height: "64px" }} />
    </>
  );
};

export default Header;