import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  CssBaseline,
  IconButton,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Upload, Copy, Check } from "lucide-react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { toast } from "react-toastify";
import Latex from "./Latex";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PicToLatex = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isLoggedIn = user.isLoggedIn;

  const [selectedImage, setSelectedImage] = useState(null);
  const [latexResult, setLatexResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showSignupDialog, setShowSignupDialog] = useState(false);
  const [usageCount, setUsageCount] = useState(() => {
    return parseInt(localStorage.getItem("latexConverterUsage") || "0");
  });

  const FREE_USES = parseInt(process.env.REACT_APP_FREE_USES || "10");

  useEffect(() => {
    localStorage.setItem("latexConverterUsage", usageCount.toString());
  }, [usageCount]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!isLoggedIn && usageCount >= FREE_USES) {
      setShowSignupDialog(true);
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    setSelectedImage(imageUrl);
    extractLatexFromImage(file);

    if (!isLoggedIn) {
      setUsageCount((prev) => prev + 1);
    }
  };

  const extractLatexFromImage = async (file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://api.mathpix.com/v3/text",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            app_id: "moinduel_391939_069b97",
            app_key:
              "f93620f23d66894460642dc8bf4e7cd9011208557744209c13d433a709c47186",
          },
        }
      );
      if (response.data.text) {
        setLatexResult(response.data.text);
        toast.success("LaTeX generated successfully!");
      }
      if (response.data.latex_styled) {
        setLatexResult(response.data.latex_styled);
        toast.success("LaTeX generated successfully!", {
          autoClose: 3000,
        });
      } else {
        toast.warning("No LaTeX content found in the image.", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Full error:", error);
      const errorMessage =
        error.response?.data?.error_info?.message ||
        error.response?.data?.error ||
        error.message;
      toast.error(`Failed to extract LaTeX: ${errorMessage}`, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadClick = () => {
    document.getElementById("file-input").click();
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(latexResult);
      setCopied(true);
      toast.success("LaTeX code copied to clipboard!", {
        autoClose: 2000,
      });
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      toast.error("Failed to copy text.", {
        autoClose: 2000,
      });
    }
  };

  const handleSignup = () => {
    setShowSignupDialog(false);
    navigate("/login");
  };

  return (
    <>
      <CssBaseline />
      <Grid container sx={{ justifyContent: "center" }}>
        <Sidebar />
        <Grid item sx={{ 
            marginLeft: { xs: 0, md: '240px' },
            width: { xs: '100%', md: 'calc(100% - 240px)',xl: "calc(100% - 450px)" }
          }}>
          <Box sx={{ padding: "20px", margin: "auto" }}>
            <div style={{ padding: "20px" }}>
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Picture to LaTeX Converter
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Convert mathematical equations from images to LaTeX code.
                  {!isLoggedIn && (
                    <Box sx={{ mt: 1, color: "warning.main" }}>
                      {FREE_USES - usageCount} free conversions remaining.
                    </Box>
                  )}
                </Typography>
              </Box>

              <Box sx={{ mt: 4 }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  sx={{ mb: 1 }}
                >
                  Upload Image
                </Typography>
                <input
                  type="file"
                  id="file-input"
                  accept=".jpg,.jpeg,.png"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                <Box
                  onClick={handleUploadClick}
                  sx={{
                    border: "2px dashed #e0e0e0",
                    borderRadius: "8px",
                    padding: "40px",
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: "#fafafa",
                    marginBottom: "24px",
                    transition: "border-color 0.2s",
                    "&:hover": {
                      borderColor: "#50C878",
                    },
                  }}
                >
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "200px",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <>
                      <Upload size={48} color="#666" />
                      <Typography sx={{ mt: 2, color: "#666" }}>
                        Click or drag image to upload
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Supports JPG, JPEG, PNG
                      </Typography>
                    </>
                  )}
                </Box>

                {latexResult && (
                  <>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ mb: 1, mt: 4 }}
                    >
                      LaTeX Equation
                    </Typography>
                    <Box
                      sx={{
                        mb: 3,
                        p: 2,
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    >
                      <Latex formula={latexResult} />
                    </Box>

                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ mb: 1 }}
                    >
                      LaTeX Code
                    </Typography>
                    <Paper
                      sx={{
                        position: "relative",
                        backgroundColor: "#f8f9fa",
                        padding: "16px",
                        paddingRight: "48px",
                        borderRadius: "8px",
                        fontFamily: "monospace",
                        overflow: "auto",
                      }}
                    >
                      <pre
                        style={{
                          margin: 0,
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-all",
                        }}
                      >
                        {latexResult}
                      </pre>
                      <IconButton
                        onClick={copyToClipboard}
                        sx={{
                          position: "absolute",
                          right: "8px",
                          top: "8px",
                          color: copied ? "#50C878" : "#666",
                        }}
                      >
                        {copied ? <Check size={20} /> : <Copy size={20} />}
                      </IconButton>
                    </Paper>
                  </>
                )}

                {isLoading && (
                  <Typography
                    sx={{ mt: 2, textAlign: "center", color: "#666" }}
                  >
                    Converting image to LaTeX...
                  </Typography>
                )}
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>

      {/* Sign Up Dialog */}
      <Dialog
        open={showSignupDialog}
        onClose={() => setShowSignupDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Free Trial Completed</DialogTitle>
        <DialogContent>
          <Typography>
            You've used all your free conversions. Sign up to continue using our
            LaTeX converter and get access to all features!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSignupDialog(false)} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={handleSignup}
            variant="contained"
            sx={{ bgcolor: "#0F172A" }}
          >
            Sign Up Now
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PicToLatex;
