import React from "react";
import { 
  Grid, 
  Typography,
  Box,
  CssBaseline,
} from "@mui/material";
import Sidebar from "./Sidebar";

const TermsAndConditions = () => {
  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid 
          item 
          sx={{ 
            marginLeft: { xs: 0, md: '240px' },
            width: { xs: '100%', md: 'calc(100% - 240px)',xl: "calc(100% - 450px)" }
          }}
        >
          <Box sx={{ padding: "20px" }}>
            <div style={{ padding: "20px" }}>
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Terms and Conditions
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Please read these terms carefully before using Moinduel.
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                  Last updated: October 24, 2024
                </Typography>
              </Box>

              <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Acceptance of Terms
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  By using Moinduel, you agree to these terms. If you're under 13, you need parent/guardian permission to use our services.
                </Typography>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Account Rules
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary">• Keep your password secure</Typography>
                  <Typography variant="body2" color="text.secondary">• Provide accurate information</Typography>
                  <Typography variant="body2" color="text.secondary">• One account per person</Typography>
                  <Typography variant="body2" color="text.secondary">• Don't share accounts</Typography>
                  <Typography variant="body2" color="text.secondary">• Report any unauthorized use to us</Typography>
                </Box>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Content Guidelines
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary">• Be respectful to others</Typography>
                  <Typography variant="body2" color="text.secondary">• Don't post harmful or inappropriate content</Typography>
                  <Typography variant="body2" color="text.secondary">• Don't share personal information</Typography>
                  <Typography variant="body2" color="text.secondary">• Report inappropriate content</Typography>
                  <Typography variant="body2" color="text.secondary">• We may remove content that violates these rules</Typography>
                </Box>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Privacy
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  We protect your privacy and only collect necessary information. See our Privacy Policy for details on how we handle your data.
                </Typography>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Safety Rules
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary">• Don't share personal information</Typography>
                  <Typography variant="body2" color="text.secondary">• Don't meet people from Moinduel in person</Typography>
                  <Typography variant="body2" color="text.secondary">• Report suspicious behavior</Typography>
                  <Typography variant="body2" color="text.secondary">• Block users who make you uncomfortable</Typography>
                  <Typography variant="body2" color="text.secondary">• Tell a trusted adult if something seems wrong</Typography>
                </Box>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Changes
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  We may update these terms. We'll notify you of important changes through the app or email.
                </Typography>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Contact Us
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Questions? Email us at infomoinduel@gmail.com
                </Typography>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TermsAndConditions;