import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  CssBaseline,
  Paper,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { MessageSquare, Star, ThumbsUp, Send } from "lucide-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "./Sidebar";

const Feedback = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [userId, setUserId] = useState("");
  const [category, setCategory] = useState("general");
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");
  const [showThankYouDialog, setShowThankYouDialog] = useState(false);

  useEffect(() => {
    if (user.loginMethod === "google") {
      setUserId(user.googleUser.userId);
      setEmail(user.googleUser.email || "");
    } else if (user.loginMethod === "custom") {
      setUserId(user.customUser.userId);
      setEmail(user.customUser.email || "");
    } else {
      setUserId("");
      setEmail("");
    }
  }, [user]);

  const feedbackCategories = [
    {
      id: "general",
      label: "General Feedback",
      icon: <MessageSquare size={24} />
    },
    {
      id: "suggestion",
      label: "Feature Suggestion",
      icon: <Star size={24} />
    },
    {
      id: "issue",
      label: "Report Issue",
      icon: <ThumbsUp size={24} />
    }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Replace YOUR_FORM_ID with your actual Formspree form ID
      const response = await fetch("https://formspree.io/f/meoqeagw", {
        method: "POST",
        body: JSON.stringify({
          category,
          feedback,
          email,
          userId: userId || "Anonymous",
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
      });

      if (response.ok) {
        setShowThankYouDialog(true);
        setFeedback("");
        setCategory("general");
        if (!userId) setEmail("");
      } else {
        throw new Error("Failed to submit");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Something is wrong, try again.", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleCloseDialog = () => {
    setShowThankYouDialog(false);
    navigate("/");
  };

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid
          item
          sx={{ 
            marginLeft: { xs: 0, md: '240px' },
            width: { xs: '100%', md: 'calc(100% - 240px)',xl: "calc(100% - 450px)" }
          }}
        >
          <Box sx={{ padding: "40px", }}>
            <Box>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Feedback
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Help us improve by sharing your thoughts and suggestions.
              </Typography>
            </Box>

            <Paper
              elevation={0}
              sx={{
                mt: 4,
                p: 4,
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
              }}
            >
              <form onSubmit={handleSubmit}>
                <FormControl component="fieldset" sx={{ width: "100%", mb: 4 }}>
                  <FormLabel component="legend" sx={{ mb: 2, color: "#0F172A" }}>
                    Feedback Type
                  </FormLabel>
                  <RadioGroup
                    row
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    sx={{
                      display: "grid",
                      gridTemplateColumns: { xs: "1fr", sm: "repeat(3, 1fr)" },
                      gap: 2,
                    }}
                  >
                    {feedbackCategories.map((cat) => (
                      <Paper
                        key={cat.id}
                        elevation={0}
                        sx={{
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          transition: "all 0.2s",
                          backgroundColor: category === cat.id ? "#f8f9fa" : "transparent",
                          borderColor: category === cat.id ? "#0F172A" : "#e0e0e0",
                          height: "100%",
                        }}
                      >
                        <FormControlLabel
                          value={cat.id}
                          control={<Radio sx={{ display: "none" }} />}
                          label={
                            <Box
                              sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                p: 2,
                                minHeight: "100px",
                              }}
                            >
                              <Box 
                                sx={{ 
                                  color: "#0F172A",
                                  mb: 1,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "40px"
                                }}
                              >
                                {cat.icon}
                              </Box>
                              <Typography 
                                variant="body2" 
                                sx={{ 
                                  textAlign: "center",
                                  width: "100%",
                                  lineHeight: 1.2
                                }}
                              >
                                {cat.label}
                              </Typography>
                            </Box>
                          }
                          sx={{ 
                            margin: 0,
                            width: "100%",
                            height: "100%"
                          }}
                        />
                      </Paper>
                    ))}
                  </RadioGroup>
                </FormControl>

                <TextField
                  multiline
                  rows={6}
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  placeholder="Tell us what you think..."
                  variant="outlined"
                  required
                  fullWidth
                  sx={{ mb: 3 }}
                />

                {!userId && (
                  <TextField
                    fullWidth
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email (optional)"
                    placeholder="your@email.com"
                    helperText="Leave your email if you'd like us to follow up with you."
                    sx={{ mb: 4 }}
                  />
                )}

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#0F172A",
                    padding: "8px 16px",
                    "&:hover": {
                      backgroundColor: "#1E293B",
                    },
                  }}
                  startIcon={<Send size={20} />}
                >
                  Submit Feedback
                </Button>
              </form>
            </Paper>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={showThankYouDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle>
          Thank You for Your Feedback!
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary">
            We appreciate you taking the time to share your thoughts with us. Your feedback helps us improve our platform.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{
              backgroundColor: "#0F172A",
              color: "white",
              "&:hover": {
                backgroundColor: "#1E293B",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Feedback;