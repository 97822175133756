import React from "react";
import { Grid, Typography, Box, CssBaseline } from "@mui/material";
import { Circle } from "lucide-react";
import Sidebar from "./Sidebar";

const steps = [
  {
    title: "Competition Levels",
    items: [
      "Pre Ecolier: Grade 1-2",
      "Ecolier: Grade 3-4",
      "Benjamin: Grade 5-6",
      "Cadet: Grade 7-8",
      "Junior: Grade 9-10",
      "Student: Grade 11-12",
    ],
  },
  {
    title: "Registration",
    content:
      "Register through your school or local Kangaroo Math organization. Competition is held annually in March.",
  },
  {
    title: "Competition Format",
    items: [
      "75 minutes duration",
      "30 multiple-choice questions",
      "Questions arranged in increasing difficulty",
      "3, 4, and 5 points per question based on difficulty",
      "Maximum score of 120 points",
    ],
  },
  {
    title: "Scoring System",
    items: [
      "Correct answer: +3, +4, or +5 points",
      "No answer: 0 points",
      "Wrong answer: -1 point (to discourage guessing)",
      "Initial score: 30 points for all participants",
    ],
  },
  {
    title: "Question Topics",
    items: [
      "Mathematical logic",
      "Basic algebra",
      "Geometry concepts",
      "Number patterns",
      "Counting and probability",
      "Spatial visualization",
    ],
  },
  {
    title: "Preparation Tips",
    items: [
      "Practice with past papers",
      "Focus on quick problem-solving",
      "Learn to manage time effectively",
      "Study visual and pattern problems",
      "Practice mental math skills",
    ],
  },
  {
    title: "Competition Day",
    items: [
      "Bring required stationery",
      "Calculators are not allowed",
      "Read instructions carefully",
      "Manage your time wisely",
      "Check your answers if time permits",
    ],
  },
  {
    title: "Results and Awards",
    items: [
      "Results typically announced within 2 months",
      "Top 1% receive gold certificates",
      "Next 9% receive silver certificates",
      "Next 20% receive bronze certificates",
      "All participants receive participation certificates",
    ],
  },
];

const KangarooRoadmap = () => {
  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid 
          item 
          sx={{ 
            marginLeft: { xs: 0, md: '240px' },
            width: { xs: '100%', md: 'calc(100% - 240px)',xl: "calc(100% - 450px)" }
          }}
        >
          <Box sx={{ padding: "20px" }}>
            <div style={{ padding: "20px" }}>
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Kangaroo Math Competition Roadmap
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Your complete guide to the International Mathematical Kangaroo Competition.
                </Typography>
              </Box>

              <Box sx={{ mt: 4, position: "relative" }}>
                {/* Vertical Timeline Line */}
                <Box
                  sx={{
                    position: "absolute",
                    left: "7px",
                    top: "24px",
                    bottom: "0",
                    width: "2px",
                    backgroundColor: "#e0e0e0",
                    zIndex: 0,
                  }}
                />

                {steps.map((step, index) => (
                  <Box
                    key={index}
                    sx={{
                      mb: 3,
                      position: "relative",
                      pl: "30px"
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: "2px",
                        backgroundColor: "white",
                        zIndex: 1,
                      }}
                    >
                      <Circle size={14} />
                    </Box>

                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ mb: 1 }}
                    >
                      {step.title}
                    </Typography>

                    {step.items ? (
                      <Box sx={{ ml: 2 }}>
                        {step.items.map((item, i) => (
                          <Typography
                            key={i}
                            variant="body2"
                            color="text.secondary"
                            sx={{ mb: 1 }}
                          >
                            • {item}
                          </Typography>
                        ))}
                      </Box>
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ ml: 2 }}
                      >
                        {step.content}
                      </Typography>
                    )}
                  </Box>
                ))}

                <Box sx={{ mt: 4, ml: "30px" }}>
                  <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                    Global Reach
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                    Kangaroo Math Competition is one of the largest mathematical competitions in the world, with more than 6 million participants from over 80 countries. The competition promotes mathematical thinking and problem-solving skills in an engaging format. 🦘
                  </Typography>
                </Box>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default KangarooRoadmap;