// voiceConfig.js
export const VOICE_IDS = {
    JOSH: "TxGEqnHWrfWFTfGW9XjX",    // Professional male voice
    RACHEL: "21m00Tcm4TlvDq8ikWAM",  // Professional female voice
    ADAM: "pNInz6obpgDQGcFmaJgB",    // Natural male voice
    NICOLE: "piTKgcLEGmPE4e6mEKli",  // Natural female voice
    ANTONI: "ErXwobaYiN019PkySvjV",   // Professional male voice
  };
  
  export const VOICE_STYLES = {
    PROFESSIONAL: {
      stability: 0.5,
      similarity_boost: 0.75,
      style: 0.7,
      speaking_rate: 1,
    },
    CASUAL: {
      stability: 0.3,
      similarity_boost: 0.85,
      style: 0.9,
      speaking_rate: 1.1,
    },
    ENGAGING: {
      stability: 0.4,
      similarity_boost: 0.8,
      style: 0.8,
      speaking_rate: 1.05,
    }
  };
  
  export const DEFAULT_VOICE_SETTINGS = {
    model_id: "eleven_multilingual_v2",
    voice_settings: VOICE_STYLES.ENGAGING
  };