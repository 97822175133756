import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  CssBaseline,
  IconButton,
} from "@mui/material";
import { Mic, PhoneOff, Phone, Camera, MessageSquare } from "lucide-react";
import OpenAI from "openai";
import Sidebar from "./Sidebar";
import { VOICE_IDS } from './voiceConfig';
const ELEVENLABS_API_KEY = "sk_6effc1af26155b8db10f4ab1aa69d005bb747746c9821ffe";
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const AiTeacherChat = () => {
  const [isInCall, setIsInCall] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioStream, setAudioStream] = useState(null);
  const [status, setStatus] = useState("");
  const [audioLevel, setAudioLevel] = useState(0);
  const [messages, setMessages] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(VOICE_IDS.RACHEL);
  
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const audioContext = useRef(null);
  const analyser = useRef(null);
  const messagesEndRef = useRef(null);
  const audioPlayer = useRef(null);
  const currentAudioSource = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    return () => {
      if (audioPlayer.current) {
        audioPlayer.current.pause();
        audioPlayer.current.src = '';
        audioPlayer.current = null;
      }
      if (currentAudioSource.current) {
        currentAudioSource.current.disconnect();
        currentAudioSource.current = null;
      }
    };
  }, []);

  const updateAudioLevel = () => {
    if (analyser.current && isRecording) {
      const dataArray = new Uint8Array(analyser.current.frequencyBinCount);
      analyser.current.getByteFrequencyData(dataArray);
      const average = dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
      setAudioLevel(average);
      requestAnimationFrame(updateAudioLevel);
    }
  };

  const speakText = async (text) => {
    try {
      // Cancel any ongoing speech
      if (audioPlayer.current) {
        audioPlayer.current.pause();
        if (currentAudioSource.current) {
          currentAudioSource.current.disconnect();
        }
      }

      setIsSpeaking(true);
      
      const response = await fetch(
        `https://api.elevenlabs.io/v1/text-to-speech/${selectedVoice}/stream`,
        {
          method: 'POST',
          headers: {
            'Accept': 'audio/mpeg',
            'xi-api-key': ELEVENLABS_API_KEY,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text,
            model_id: "eleven_multilingual_v2",
            voice_settings: {
              stability: 0.5,
              similarity_boost: 0.75,
            }
          })
        }
      );
      console.log("API Key available:", !!ELEVENLABS_API_KEY);
      if (!response.ok) throw new Error('ElevenLabs API request failed');

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      
      const audio = new Audio(audioUrl);
      audioPlayer.current = audio;
      
      audio.onended = () => {
        setIsSpeaking(false);
        URL.revokeObjectURL(audioUrl);
      };

      audio.onerror = (error) => {
        console.error('Audio playback error:', error);
        setIsSpeaking(false);
        URL.revokeObjectURL(audioUrl);
      };

      await audio.play();
      
    } catch (error) {
      console.error('Speech generation error:', error);
      setIsSpeaking(false);
    }
  };

  const handleAIResponse = async (userInput) => {
    if (!isInCall) return;

    const userMessage = {
      type: "user",
      content: userInput,
      timestamp: new Date().toISOString(),
    };
    setMessages((prev) => [...prev, userMessage]);
    
    try {
      const completion = await openai.chat.completions.create({
        model: "gpt-4-turbo-preview",
        messages: [
          {
            role: "system",
            content: "You are a helpful and knowledgeable teacher who explains concepts clearly and concisely.",
          },
          { role: "user", content: userInput },
        ],
        max_tokens: 150,
        temperature: 0.7,
      });

      if (!isInCall) return;

      const aiResponse = completion.choices[0].message.content;
      const aiMessage = {
        type: "ai",
        content: aiResponse,
        timestamp: new Date().toISOString(),
      };
      setMessages((prev) => [...prev, aiMessage]);
      
      if (isInCall) {
        await speakText(aiResponse);
      }
    } catch (error) {
      console.error("Error calling OpenAI:", error);
      if (!isInCall) return;
      
      const errorMessage = {
        type: "ai",
        content: "I apologize, but I'm having trouble connecting right now. Please try again.",
        timestamp: new Date().toISOString(),
      };
      setMessages((prev) => [...prev, errorMessage]);
      if (isInCall) {
        await speakText(errorMessage.content);
      }
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);

      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      analyser.current = audioContext.current.createAnalyser();
      const source = audioContext.current.createMediaStreamSource(stream);
      source.connect(analyser.current);
      analyser.current.fftSize = 256;
      updateAudioLevel();

      const recorder = new MediaRecorder(stream);
      mediaRecorder.current = recorder;
      audioChunks.current = [];

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      recorder.onstop = async () => {
        if (!isInCall) return;
        
        setStatus("Converting speech to text...");
        const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });

        try {
          const audioFile = new File([audioBlob], "audio.webm", {
            type: "audio/webm",
          });

          const transcription = await openai.audio.transcriptions.create({
            file: audioFile,
            model: "whisper-1",
          });

          if (transcription.text && isInCall) {
            setStatus("");
            handleAIResponse(transcription.text);
          }
        } catch (error) {
          console.error("Error transcribing audio:", error);
          setStatus("Error transcribing audio. Please try again.");
          setTimeout(() => setStatus(""), 3000);
        }
      };

      recorder.start();
      setIsRecording(true);
      setStatus("Listening... Click the mic button again to stop.");
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setStatus("Error accessing microphone. Please check your permissions.");
      setTimeout(() => setStatus(""), 3000);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      audioStream.getTracks().forEach((track) => track.stop());
      if (audioContext.current) {
        audioContext.current.close();
      }
      setIsRecording(false);
      setAudioStream(null);
      setAudioLevel(0);
    }
  };

  const startCall = async () => {
    setIsInCall(true);
    setStatus("Start speaking to interact with your AI teacher");
  };

  const endCall = () => {
    // Stop any ongoing speech
    if (audioPlayer.current) {
      audioPlayer.current.pause();
      audioPlayer.current.src = '';
      audioPlayer.current = null;
    }
    
    // Clean up any audio source
    if (currentAudioSource.current) {
      currentAudioSource.current.disconnect();
      currentAudioSource.current = null;
    }
    
    stopRecording();
    setIsInCall(false);
    setStatus("");
    setMessages([]);
    setIsSpeaking(false);
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const generateWaveBars = () => {
    const bars = [];
    const numBars = 5;
    const baseHeight = 12;
    
    for (let i = 0; i < numBars; i++) {
      const height = isRecording 
        ? baseHeight + (audioLevel / 255) * baseHeight * Math.sin(Date.now() / 200 + i) 
        : baseHeight;
      
      bars.push(
        <Box
          key={i}
          sx={{
            width: 2,
            height: `${height}px`,
            bgcolor: "white",
            mx: 0.3,
            borderRadius: 1,
            transition: "height 0.1s ease",
          }}
        />
      );
    }
    return bars;
  };

  const VoiceSelector = () => (
    <Box sx={{ mb: 2 ,pt: 2}}>
      <Typography variant="subtitle2" sx={{ mb: 0,  }}>
        Select Voice
      </Typography>
      <select
        value={selectedVoice}
        onChange={(e) => setSelectedVoice(e.target.value)}
        style={{
          padding: '8px',
          borderRadius: '4px',
          border: '1px solid rgb(226, 232, 240)'
        }}
      >
        <option value={VOICE_IDS.RACHEL}>Rachel (Professional Female)</option>
        <option value={VOICE_IDS.JOSH}>Josh (Professional Male)</option>
        <option value={VOICE_IDS.ADAM}>Adam (Natural Male)</option>
        <option value={VOICE_IDS.NICOLE}>Nicole (Natural Female)</option>
        <option value={VOICE_IDS.ANTONI}>Antoni (Professional Male)</option>
      </select>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid
          item
          sx={{
            marginLeft: { xs: 0, md: "240px" },
            width: {
              xs: "100%",
              md: "calc(100% - 240px)",
              xl: "calc(100% - 450px)",
            },
            position: "relative",
            overflow: "hidden",
          }}
        >
          {!isInCall ? (
            <Box sx={{ 
              padding: "40px",
              height: "100vh",
              display: "flex",
              flexDirection: "column" 
            }}>
              <Box sx={{ mb: 4 }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 1,
                  justifyContent: 'space-between' 
                }}>
                  <Typography 
                    sx={{ 
                      fontSize: '1.25rem',
                      fontWeight: 600,
                      color: 'rgb(15, 23, 42)',
                    }}
                  >
                    AI Teacher Voice Chat
                    <Typography 
                  sx={{ 
                    fontSize: '0.875rem',
                    color: 'rgb(100, 116, 139)'
                  }}
                >
                  Start a voice call with your AI teacher
                </Typography>
                  </Typography>
                  <Box sx={{ transform: 'translateY(-2px)' }}>
                    <VoiceSelector />
                  </Box>
                </Box>
                
              </Box>

              <Box sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "rgb(241, 245, 249)",
                borderRadius: 2,
                mx: 3,
                my: 2
              }}>
                <Box
                  sx={{
                    width: 120,
                    height: 120,
                    borderRadius: "50%",
                    bgcolor: "rgb(15, 23, 42)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 3
                  }}
                >
                  <Camera size={48} color="white" />
                </Box>

                <IconButton
                  onClick={startCall}
                  sx={{
                    width: 64,
                    height: 64,
                    bgcolor: 'rgb(34, 197, 94)',
                    color: "white",
                    "&:hover": {
                      bgcolor: 'rgb(22, 163, 74)',
                    },
                    mb: 2
                  }}
                >
                  <Phone size={32} />
                </IconButton>

                <Typography sx={{ color: 'rgb(100, 116, 139)' }}>
                  Click to start call
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={{ 
              padding: "40px",
              height: "100vh",
              display: "flex",
            }}>
              <Box sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                bgcolor: "rgb(241, 245, 249)",
                position: "relative",
              }}>
                <Box sx={{
                  p: 2,
                  borderBottom: "1px solid",
                  borderColor: "rgb(226, 232, 240)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}>
                  <Typography sx={{
                    fontSize: '1rem',
                    color: 'rgb(15, 23, 42)',
                    fontWeight: 500
                  }}>
                    Voice On
                  </Typography>
                  <IconButton
                    onClick={() => setShowChat(prev => !prev)}
                    sx={{
                      color: 'rgb(100, 116, 139)',
                    }}
                  >
                    <MessageSquare size={20} />
                  </IconButton>
                </Box>

                <Box sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 3,
                }}>
                  <Box
                    sx={{
                      width: 140,
                      height: 140,
                      borderRadius: "50%",
                      bgcolor: "rgb(15, 23, 42)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mb: 4,
                      animation: isSpeaking ? "bounce 1s infinite" : "none"
                    }}
                  >
                    <Camera size={56} color="white" />
                  </Box>

                  <Typography sx={{
                    color: 'rgb(100, 116, 139)',
                    mb: 4
                  }}>
                    {status || "Start speaking"}
                  </Typography>

                  <Box sx={{
                    display: "flex",
                    gap: 3,
                  }}>
                    <IconButton
                      onClick={toggleRecording}
                      sx={{
                        width: 56,
                        height: 56,
                        bgcolor: isRecording ? 'rgb(239, 68, 68)' : 'rgb(226, 232, 240)',
                        color: isRecording ? 'white' : 'rgb(15, 23, 42)',
                        "&:hover": {
                          bgcolor: isRecording ? 'rgb(220, 38, 38)' : 'rgb(203, 213, 225)',
                        },
                      }}
                    >
                      <Mic size={24} />
                    </IconButton>

                    <IconButton
                      onClick={endCall}
                      sx={{
                        width: 56,
                        height: 56,
                        bgcolor: 'rgb(239, 68, 68)',
                        color: 'white',
                        "&:hover": {
                          bgcolor: 'rgb(220, 38, 38)',
                        },
                      }}
                    >
                      <PhoneOff size={24} />
                    </IconButton>
                  </Box>

                  {isRecording && (
                    <Box sx={{
                      mt: 4,
                      display: "flex",
                      justifyContent: "center",
                      gap: 0.5
                    }}>
                      {generateWaveBars()}
                    </Box>
                  )}
                </Box>
              </Box>

              {showChat && (
                <Box
                  sx={{
                    width: 320,
                    borderLeft: "1px solid",
                    borderColor: "rgb(226, 232, 240)",
                    bgcolor: "white",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{
                    flex: 1,
                    overflowY: "auto",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2
                  }}>
                    {messages.map((message, index) => (
                      <Box
                        key={index}
                        sx={{
                          alignSelf: message.type === "user" ? "flex-end" : "flex-start",
                          maxWidth: "80%",
                          bgcolor: message.type === "user" ? 'rgb(59, 130, 246)' : 'rgb(241, 245, 249)',
                          color: message.type === "user" ? 'white' : 'rgb(15, 23, 42)',
                          borderRadius: 2,
                          p: 2,
                        }}
                      >
                        <Typography variant="body2">
                          {message.content}
                        </Typography>
                      </Box>
                    ))}
                    <div ref={messagesEndRef} />
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Grid>

      <style>
        {`
          @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
          }
        `}
      </style>
    </>
  );
};

export default AiTeacherChat;