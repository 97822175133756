import React from "react";
import {
  Grid,
  Typography,
  Box,
  CssBaseline,
  Paper,
} from "@mui/material";
import { Rocket, Brain, Trophy, Timer, Binary, Sparkles } from "lucide-react";
import Sidebar from "./Sidebar";

const Learn = () => {
  const features = [
    {
      icon: <Brain size={24} />,
      title: "Smart Learning Path",
      description: "Personalized learning journey based on your skill level and goals"
    },
    {
      icon: <Trophy size={24} />,
      title: "Interactive Challenges",
      description: "Practice with real-time feedback and competitive elements"
    },
    {
      icon: <Timer size={24} />,
      title: "Time Management",
      description: "Learn to solve problems efficiently under time pressure"
    },
    {
      icon: <Binary size={24} />,
      title: "Advanced Topics",
      description: "Deep dive into complex mathematical concepts and techniques"
    },
    {
      icon: <Sparkles size={24} />,
      title: "AI-Powered Solutions",
      description: "Get detailed explanations powered by advanced AI"
    }
  ];

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid 
          item 
           
          sx={{ 
            
            marginLeft: { xs: 0, md: '240px' }, // Adjust margin based on sidebar width
            width: { xs: '100%', md: 'calc(100% - 240px)',xl: "calc(100% - 450px)" } // Adjust width to account for sidebar
          }}
        >
          <Box sx={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
            <div style={{ padding: "20px" }}>
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Learning Hub
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Your journey to mathematical excellence starts here.
                </Typography>
              </Box>

              {/* Coming Soon Banner */}
              <Box
                sx={{
                  mt: 4,
                  p: 6,
                  textAlign: "center",
                  backgroundColor: "#f8f9fa",
                  borderRadius: "16px",
                  position: "relative",
                  overflow: "hidden",
                  maxWidth: "100%", // Ensure box doesn't overflow
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: "4px",
                    background: "linear-gradient(90deg, #50C878 0%, #4a90e2 100%)",
                  }}
                />
                
                <Rocket size={48} color="#50C878" />
                
                <Typography 
                  variant="h4" 
                  sx={{ 
                    mt: 3,
                    mb: 2,
                    background: "linear-gradient(90deg, #50C878 0%, #4a90e2 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "bold"
                  }}
                >
                  Coming Soon
                </Typography>
                
                <Typography 
                  variant="body1" 
                  color="text.secondary"
                  sx={{ 
                    maxWidth: "600px", 
                    margin: "0 auto", 
                    mb: 4,
                    px: 2 // Add horizontal padding for small screens
                  }}
                >
                  We're building something amazing! A comprehensive learning platform 
                  that combines competitive mathematics with adaptive learning technology.
                </Typography>
              </Box>

              {/* Features Grid */}
              <Box sx={{ mt: 6 }}>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 3 }}>
                  What to Expect
                </Typography>
                
                <Grid container spacing={3}>
                  {features.map((feature, index) => (
                    <Grid item xs={12} sm={6} lg={4} key={index}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          height: "100%",
                          border: "1px solid #e0e0e0",
                          borderRadius: "12px",
                          transition: "transform 0.2s, box-shadow 0.2s",
                          "&:hover": {
                            transform: "translateY(-4px)",
                            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                          }
                        }}
                      >
                        <Box sx={{ color: "#50C878", mb: 2 }}>
                          {feature.icon}
                        </Box>
                        <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1 }}>
                          {feature.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {feature.description}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/* Newsletter Section */}
              <Box
                sx={{
                  mt: 6,
                  p: 4,
                  textAlign: "center",
                  backgroundColor: "#f8f9fa",
                  borderRadius: "12px",
                  maxWidth: "100%", // Ensure box doesn't overflow
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Stay Updated
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  More features are on the way! Keep practicing with our duels and 
                  competitions while we prepare an even better learning experience for you.
                </Typography>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Learn;