import React from "react";
import { 
  Grid, 
  Typography,
  Box,
  CssBaseline,
} from "@mui/material";
import Sidebar from "./Sidebar";

const PrivacyPolicy = () => {
  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid item sx={{ 
              marginLeft: { xs: 0, md: '240px' },
              width: { xs: '100%', md: 'calc(100% - 240px)',xl: "calc(100% - 450px)" }
            }}>
          <Box sx={{ padding: "20px", }}>
            <div style={{ padding: "20px" }}>
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Privacy Policy
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  How Moinduel protects and handles your information.
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                  Last updated: October 24, 2024
                </Typography>
              </Box>

              <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Information We Collect
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary">• Your name and email address</Typography>
                  <Typography variant="body2" color="text.secondary">• Profile information you choose to share</Typography>
                  <Typography variant="body2" color="text.secondary">• School information</Typography>
                  <Typography variant="body2" color="text.secondary">• How you use our platform</Typography>
                  <Typography variant="body2" color="text.secondary">• Device information like browser type and IP address</Typography>
                </Box>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  How We Use Your Information
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary">• To create and manage your account</Typography>
                  <Typography variant="body2" color="text.secondary">• To improve our services</Typography>
                  <Typography variant="body2" color="text.secondary">• To keep our platform safe</Typography>
                  <Typography variant="body2" color="text.secondary">• To communicate with you about our services</Typography>
                  <Typography variant="body2" color="text.secondary">• To provide support when you need help</Typography>
                </Box>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Information Sharing
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary">We do not sell your personal information. We may share information with:</Typography>
                  <Typography variant="body2" color="text.secondary">• Service providers who help run our platform</Typography>
                  <Typography variant="body2" color="text.secondary">• Other users (only what you choose to share)</Typography>
                  <Typography variant="body2" color="text.secondary">• Law enforcement when required by law</Typography>
                </Box>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Your Privacy Choices
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary">• Control what appears on your profile</Typography>
                  <Typography variant="body2" color="text.secondary">• Choose what to share with other users</Typography>
                  <Typography variant="body2" color="text.secondary">• Update or delete your account information</Typography>
                  <Typography variant="body2" color="text.secondary">• Opt-out of promotional emails</Typography>
                  <Typography variant="body2" color="text.secondary">• Request your data or its deletion</Typography>
                </Box>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Data Security
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  We use industry-standard security measures to protect your information. However, no online service is completely secure, so please use strong passwords and keep your login information private.
                </Typography>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Cookies and Similar Technologies
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  We use cookies to keep you logged in and improve your experience. You can control cookie settings in your browser.
                </Typography>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Parents and Guardians
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  If you're under 13, we need parent/guardian permission. Parents can review their child's personal information, request deletion, or refuse further collection by contacting us.
                </Typography>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Changes to Privacy Policy
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  We'll notify you about significant changes through our platform or via email.
                </Typography>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Contact Us
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  For privacy questions or concerns, email us at infomoinduel@gmail.com
                </Typography>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PrivacyPolicy;