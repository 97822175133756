import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  Grid,
  CssBaseline,
  InputAdornment,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Sidebar from "./Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import Latex from "./Latex";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
//import { fetchPosts } from "../redux/reducers/postReducer";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import {
  TelegramShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { fetchPosts } from "../redux/reducers/postReducer";
import MarkdownMathRenderer from "./MarkdownMathRenderer";

const ReadPost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postId } = useParams();
  const { isLoggedIn, loginMethod, googleUser, customUser } = useSelector(
    (state) => state.user
  ); // const isLoggedIn = user.isLoggedIn;

  const [commentText, setCommentText] = useState("");
  const [latexResult, setLatexResult] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [attachedFile, setAttachedFile] = useState(null);

  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [userId, setUserId] = useState("");
  const [open, setOpen] = useState(false); // State to control the popup
  const [post, setPost] = useState("");
  const [markDownResult, setMarkDownResult] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const postUrl = `${window.location.origin}/post/${postId}`;

  const isAdmin =
    (googleUser?.role &&
      Array.isArray(googleUser.role) &&
      googleUser.role.includes("Admin")) ||
    (customUser?.role &&
      Array.isArray(customUser.role) &&
      customUser.role.includes("Admin"));

  useEffect(() => {
    if (loginMethod === "google") {
      setUserId(googleUser.userId);
    } else if (loginMethod === "custom") {
      setUserId(customUser.userId);
    }

    if (postId) {
      getPostById(postId);
    }
  }, [postId, customUser, googleUser, loginMethod]);

  const getPostById = async (postId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/Posts/GetPostById/${postId}`
      );
      if (response.status === 200) {
        setPost(response.data);
      } else {
        console.error(
          "Failed to fetch post: Unexpected status code",
          response.status
        );
      }
    } catch (error) {
      console.error("Failed to fetch post:", error.message);
    }
  };

  useEffect(() => {
    // Check if post and post.likes are available before calculating likes
    if (post && Array.isArray(post.likes)) {
      const postlikeCount = post.likes.filter(
        (like) => like.likeStatus === true
      ).length;
      setLikeCount(postlikeCount);
    }
  }, [post]);

  const [comments, setComments] = useState(post.comments || []);

  const handleCommentChange = (event) => {
    const value = event.target.value;
    setCommentValue(value);

    if (value.includes("$")) {
      setMarkDownResult(value);
    } else {
      setCommentText(value);
    }
  };
  const isButtonDisabled =
    !commentValue.trim() && !markDownResult && !latexResult;

  const handlePostComment = async () => {
    if (!isLoggedIn) navigate("/login");

    const commentData = {
      postId: post.postId,
      commentText: commentText,
      latex_Text: markDownResult,
      latex: latexResult,
      commenterId: userId,
    };

    console.log("comment data :", commentData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Posts/${post.postId}/comment`,
        commentData
      );
      if (response.status === 200) {
        const newComment = response.data; // Assuming the API returns the created comment

        setComments((prevComments) => [...prevComments, newComment]);

        setCommentText("");
        setLatexResult("");
        setMarkDownResult("");
        setCommentValue("");
        getPostById(post.postId);
        dispatch(fetchPosts());
      }
    } catch (error) {
      console.error("Error posting comment:", error);
      toast.error("Failed to post comment, try again!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleImageChange = (event) => {
    console.log("image added");
    const file = event.target.files[0];
    setAttachedFile(file);
    if (file) {
      extractLatexFromImage(file); // Call the API to get LaTeX
      event.target.value = null;
      setAttachedFile(null);
    }
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const extractLatexFromImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file); // Add the file to form data

    try {
      const response = await axios.post(
        "https://api.mathpix.com/v3/text",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            app_id: "moinduel_391939_069b97",
            app_key:
              "f93620f23d66894460642dc8bf4e7cd9011208557744209c13d433a709c47186",
          },
        }
      );
      console.log("response : ", response);

      let extractedText = response.data.latex_styled || response.data.text;
      console.log("extracted : ", extractedText);

      if (extractedText === response.data.text) {
        extractedText = replaceMathDelimiters(extractedText);

        console.log(extractedText);
        setMarkDownResult(extractedText);
      } else {
        console.log("in else : ", extractedText);

        setLatexResult(extractedText);
      }
    } catch (error) {
      console.error("Error extracting text:", error);
      toast.error("Failed to extract LaTeX from the image.", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const replaceMathDelimiters = (text) => {
    return (
      text
        // Replace \( and \) with $
        .replace(/\\\(/g, "$")
        .replace(/\\\)/g, "$")
        // Replace \[ and \] with $$
        .replace(/\\\[/g, "$$")
        .replace(/\\\]/g, "$$")
    );
  };

  const handleLikeClick = async () => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Like/like`,
        {
          postId: postId,
          userId: userId,
          likeStatus: !isLiked,
        }
      );

      // If the API call is successful, toggle the like status and update the like count
      if (response.status === 200) {
        setIsLiked(!isLiked);
        setLikeCount((prevCount) => (isLiked ? prevCount - 1 : prevCount + 1)); // Adjust count based on like status
        dispatch(fetchPosts());
      }
    } catch (error) {
      console.error("Error updating like status:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const handleDeleteClick = async (commentId) => {
    handleMenuClose();
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/Posts/DeleteComment/${commentId}`
      );
      if (res.status === 200) {
        getPostById(postId);
        toast.success("Comment deleted successfully.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      } else {
        toast.error("Comment not found.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      console.error("Failed to delete the post", error);
      toast.error("Comment not found.", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };
  // const handleShare = (platform) => {
  //   // Implement the sharing logic based on the platform
  //   console.log(`Sharing on ${platform}`);
  //   handleClose();
  // };
  //it is not used anywhere?

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid
          item
          sx={{
            marginLeft: { xs: 0, md: "240px" },
            width: {
              xs: "100%",
              md: "calc(100% - 240px)",
              xl: "calc(100% - 450px)",
            },
          }}
        >
          <Box sx={{ padding: "20px" }}>
            <div style={{ padding: "20px" }}>
              <IconButton
                onClick={() => navigate("/")}
                sx={{
                  marginBottom: 0,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Box>
                {/* Title Section */}
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "16px" }}
                >
                  {post.title}
                </Typography>
                {/* Author and Date Section */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <Avatar alt="Otajon Yo'ldashev" src="/path-to-avatar.jpg" />
                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {post.createdBy}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(post.createdDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: "20px", textAlign: "center" }}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={2}
                  >
                    {/* Image Section */}
                    <Grid item>
                      {post.imagePath ? (
                        <img
                          src={`https://moinduel.somee.com/${post.imagePath}`}
                          alt="Thumbnail"
                          style={{
                            width: "100%", // Adjust the width as needed
                            height: "auto", // Maintain the aspect ratio
                            marginBottom: "10px",
                          }}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        ></Typography>
                      )}
                    </Grid>

                    {/* LaTeX Section */}
                    <Grid item>
                      {post.latex ? (
                        <Box sx={{ marginTop: "20px", textAlign: "center" }}>
                          <Latex formula={post.latex} />
                        </Box>
                      ) : (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        ></Typography>
                      )}
                    </Grid>
                    <Grid item>
                      {post.latex_Text ? (
                        <Box sx={{ marginTop: "20px", textAlign: "center" }}>
                          <MarkdownMathRenderer
                            markdownContent={post.latex_Text}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        ></Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                {/* Post Content */}
                <Card elevation={0} sx={{ marginBottom: "20px" }}>
                  <CardContent>
                    <Typography variant="body1" paragraph>
                      {post.content}
                    </Typography>
                  </CardContent>
                </Card>
                {/* Interaction Section (Likes, Comments) */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      onClick={handleLikeClick}
                      color={isLiked ? "primary" : "default"} // Change color based on like status
                    >
                      <ThumbUpIcon fontSize="small" />
                    </IconButton>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ marginLeft: "5px" }}
                    >
                      {likeCount}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton onClick={handleClickOpen} color="default">
                      <ShareIcon fontSize="small" />
                    </IconButton>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ marginLeft: "5px" }}
                    ></Typography>

                    {/* Dialog for sharing platforms */}
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      fullWidth
                      maxWidth="sm"
                    >
                      <DialogTitle>
                        Share Link
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{ position: "absolute", top: 8, right: 8 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                        <p>
                          Anyone who has this link will be able to view this.
                        </p>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <TelegramShareButton
                            url={postUrl}
                            quote={post.title}
                            style={{ margin: "0 10px" }}
                          >
                            <TelegramIcon size={40} round />
                          </TelegramShareButton>
                          <FacebookShareButton
                            url={postUrl}
                            quote={post.title}
                            style={{ margin: "0 10px" }}
                          >
                            <FacebookIcon size={40} round />
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={postUrl}
                            title={post.title}
                            style={{ margin: "0 10px" }}
                          >
                            <TwitterIcon size={40} round />
                          </TwitterShareButton>
                          <WhatsappShareButton
                            url={postUrl}
                            title={post.title}
                            style={{ margin: "0 10px" }}
                          >
                            <WhatsappIcon size={40} round />
                          </WhatsappShareButton>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </Box>
                </Box>
                <Divider sx={{ marginBottom: "20px" }} />
                {/* Comments Section */}
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "10px", fontWeight: "800" }}
                >
                  Comments
                </Typography>
                {/* Comment Input */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    placeholder="Leave a comment"
                    fullWidth
                    value={commentValue}
                    onChange={handleCommentChange}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ padding: "7.5px, 14px" }}
                          >
                            <IconButton
                              color="primary"
                              component="label"
                              sx={{
                                padding: "0px",
                              }}
                            >
                              <AttachFileIcon />
                              <input
                                type="file"
                                id="file-input"
                                accept=".jpg,.jpeg,.png" // Only accepts .jpg and .png files
                                style={{ display: "none" }}
                                onChange={handleImageChange}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={handlePostComment}
                    disabled={isButtonDisabled}
                    sx={{
                      marginLeft: "10px",
                      height: "fit-content",
                      backgroundColor: "#0F172A",
                      fontSize: "0.8rem",
                    }}
                  >
                    Post
                  </Button>
                </Box>
                {markDownResult && (
                  <MarkdownMathRenderer markdownContent={markDownResult} />
                )}
                {latexResult && <Latex formula={latexResult} />}

                {/* Comment Item */}
                <Box>
                  {post.comments?.length === 0 ? (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ marginBottom: "20px" }}
                    >
                      No Comments. Be the first to comment!
                    </Typography>
                  ) : (
                    (post.comments || []).map(
                      (
                        comment // Ensure post.comments is defined
                      ) => (
                        <Box
                          key={comment.commentId}
                          sx={{ display: "flex", marginBottom: "16px" }}
                        >
                          <Avatar
                            alt={comment.createdBy}
                            src="/path-to-avatar.jpg"
                          />
                          <Box sx={{ marginLeft: "10px", flexGrow: 1 }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 600 }}
                              >
                                {comment.createdBy}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {new Date(
                                  comment.createdDate
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                marginBottom: "5px",
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              {comment.latex && (
                                <Box
                                  sx={{
                                    marginTop: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <Latex
                                    formula={comment.latex}
                                    fontSize="0.75rem"
                                  />
                                </Box>
                              )}
                            </Box>
                            <Box
                              sx={{
                                marginBottom: "5px",
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              {comment.latex_Text && (
                                <Box
                                  sx={{
                                    marginTop: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <MarkdownMathRenderer
                                    markdownContent={comment.latex_Text}
                                  />
                                </Box>
                              )}
                            </Box>
                            <Typography variant="body2">
                              {comment.commentText}
                            </Typography>
                          </Box>
                          {isAdmin && (
                            <>
                              <IconButton onClick={(e) => handleMenuOpen(e)}>
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuItem
                                  onClick={() =>
                                    handleDeleteClick(comment.commentId)
                                  }
                                  sx={{ color: "red" }}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </Box>
                      )
                    )
                  )}
                </Box>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default ReadPost;
