import React from "react";
import {
  Grid,
  Typography,
  Box,
  CssBaseline,
  Paper,
  Button,
} from "@mui/material";
import { 
  Puzzle, 
  Brain, 
  Clock, 
  Trophy, 
  Target, 
  Zap,
  Flame,
  Crown,
  Medal
} from "lucide-react";
import Sidebar from "./Sidebar";

const DailyPuzzles = () => {
  const upcomingPuzzles = [
    {
      icon: <Brain size={24} />,
      title: "Logic Masters",
      description: "Daily logic puzzles that test your analytical thinking",
      difficulty: "Medium",
      points: "100",
      status: "Coming Soon"
    },
    {
      icon: <Clock size={24} />,
      title: "Speed Challenge",
      description: "Solve equations against the clock",
      difficulty: "Hard",
      points: "150",
      status: "Coming Soon"
    },
    {
      icon: <Target size={24} />,
      title: "Pattern Quest",
      description: "Find and complete mathematical patterns",
      difficulty: "Easy",
      points: "75",
      status: "Coming Soon"
    }
  ];

  const features = [
    {
      icon: <Zap size={24} />,
      title: "Daily Streaks",
      description: "Complete daily puzzles to build your streak and earn bonus rewards"
    },
    {
      icon: <Trophy size={24} />,
      title: "Leaderboards",
      description: "Compete globally and see where you stand among other puzzle solvers"
    },
    {
      icon: <Flame size={24} />,
      title: "Skill Progression",
      description: "Track your improvement across different types of mathematical challenges"
    },
    {
      icon: <Crown size={24} />,
      title: "Achievement System",
      description: "Earn badges and titles as you master different puzzle categories"
    },
    {
      icon: <Medal size={24} />,
      title: "Weekly Tournaments",
      description: "Participate in themed tournaments with special rewards"
    }
  ];

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid 
          item 
          
          sx={{ 
            marginLeft: { xs: 0, md: '240px' },
            width: { xs: '100%', md: 'calc(100% - 240px)',xl: "calc(100% - 450px)" }
          }}
        >
          <Box sx={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
            <div style={{ padding: "20px" }}>
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Daily Puzzles & Challenges
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Sharpen your mathematical skills with daily brain teasers and competitive challenges.
                </Typography>
              </Box>

              {/* Coming Soon Banner */}
              <Box
                sx={{
                  mt: 4,
                  p: 6,
                  textAlign: "center",
                  background: "linear-gradient(135deg, rgba(80, 200, 120, 0.05) 0%, rgba(74, 144, 226, 0.05) 100%)",
                  borderRadius: "16px",
                  border: "1px solid rgba(80, 200, 120, 0.2)",
                  position: "relative",
                  maxWidth: "100%",
                }}
              >
                <Puzzle 
                  size={48} 
                  style={{ 
                    color: "#50C878",
                    transform: "rotate(-15deg)" 
                  }} 
                />
                
                <Typography 
                  variant="h4" 
                  sx={{ 
                    mt: 3,
                    mb: 2,
                    fontWeight: "bold",
                    background: "linear-gradient(90deg, #50C878 30%, #4a90e2 70%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Launching Soon
                </Typography>
                
                <Typography 
                  variant="body1" 
                  color="text.secondary"
                  sx={{ 
                    maxWidth: "600px", 
                    margin: "0 auto", 
                    mb: 3,
                    px: { xs: 2, md: 0 } 
                  }}
                >
                  Get ready for an exciting collection of daily mathematical puzzles, 
                  challenging problems, and competitive brain teasers.
                </Typography>
              </Box>

              {/* Preview of Upcoming Puzzles */}
              <Box sx={{ mt: 6 }}>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 3 }}>
                  Featured Puzzles Preview
                </Typography>
                <Grid container spacing={3}>
                  {upcomingPuzzles.map((puzzle, index) => (
                    <Grid item xs={12} sm={6} lg={4} key={index}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          height: "100%",
                          border: "1px solid #e0e0e0",
                          borderRadius: "12px",
                          position: "relative",
                          overflow: "hidden",
                          transition: "transform 0.2s, box-shadow 0.2s",
                          "&:hover": {
                            transform: "translateY(-4px)",
                            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                          }
                        }}
                      >
                        <Box 
                          sx={{ 
                            position: "absolute",
                            top: 12,
                            right: 12,
                            fontSize: "0.75rem",
                            color: "white",
                            bgcolor: "#50C878",
                            px: 1,
                            py: 0.5,
                            borderRadius: "4px",
                            opacity: 0.9
                          }}
                        >
                          {puzzle.status}
                        </Box>
                        <Box sx={{ color: "#50C878", mb: 2 }}>
                          {puzzle.icon}
                        </Box>
                        <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1 }}>
                          {puzzle.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                          {puzzle.description}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <Typography variant="caption" color="text.secondary">
                            Difficulty: {puzzle.difficulty}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {puzzle.points} Points
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/* Features Section */}
              <Box sx={{ mt: 6 }}>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 3 }}>
                  Upcoming Features
                </Typography>
                <Grid container spacing={3}>
                  {features.map((feature, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 2,
                          border: "1px solid #e0e0e0",
                          borderRadius: "12px",
                          transition: "transform 0.2s",
                          "&:hover": {
                            transform: "translateX(4px)",
                          }
                        }}
                      >
                        <Box sx={{ color: "#50C878", mt: 0.5, flexShrink: 0 }}>
                          {feature.icon}
                        </Box>
                        <Box>
                          <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 0.5 }}>
                            {feature.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {feature.description}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/* Newsletter Section */}
              <Box
                sx={{
                  mt: 6,
                  p: 4,
                  textAlign: "center",
                  background: "linear-gradient(135deg, rgba(80, 200, 120, 0.05) 0%, rgba(74, 144, 226, 0.05) 100%)",
                  borderRadius: "12px",
                  maxWidth: "100%",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Get Ready to Challenge Yourself
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  We're putting the finishing touches on our daily puzzle system. 
                  Practice with our current features while we prepare even more challenging content!
                </Typography>
                <Button 
                  variant="contained"
                  sx={{ 
                    bgcolor: "#50C878",
                    "&:hover": {
                      bgcolor: "#45b86b"
                    }
                  }}
                >
                  Try Current Challenges
                </Button>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DailyPuzzles;