import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  Grid,
  Card,
  CardContent,
  IconButton,
  CssBaseline,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import logo from "../images/Logo For Website 02.png";
const ProfilePage = () => {
  const user = useSelector((state) => state.user);
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (user.loginMethod === "google") {
      setCurrentUser(user.googleUser);
    } else if (user.loginMethod === "custom") {
      setCurrentUser(user.customUser);
    } else {
      setCurrentUser(""); // Reset userId if no login method is found
    }
  }, [user.customUser, user.googleUser, user.loginMethod, currentUser]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <CssBaseline />
      <Grid container sx={{ justifyContent: "center" }}>
        <Sidebar />
        <Grid item sx={{ 
            
            marginLeft: { xs: 0, md: '240px' }, // Adjust margin based on sidebar width
            width: { xs: '100%', md: 'calc(100% - 240px)' } // Adjust width to account for sidebar
          }}>
          <Box sx={{ padding: "20px", margin: "auto" }}>
            <div style={{ padding: "20px" }}>
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#000",
                    color: "#fff",
                    border: "1px solid #00000014",
                    borderRadius: "15px",
                    height: "100%", // Full height for the container
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* Portion 1 - Back Button (25% height) */}
                  <Box
                    sx={{
                      height: "25%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between", // To space the icon and image
                      padding: "10px",
                    }}
                  >
                    {/* Back Button on the left */}
                    <IconButton
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        border: "1px solid #00000014",
                      }}
                      onClick={handleGoBack}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Box>

                  {/* Portion 2 - Avatar (50% height) */}
                  <Box
                    sx={{
                      height: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: "10px",
                    }}
                  >
                    <Avatar
                      alt="Profile"
                      src=""
                      sx={{
                        width: 100,
                        height: 100,
                        border: "4px solid white",
                      }}
                    />
                  </Box>

                  {/* Portion 3 - Name and Edit Button (25% height) */}
                  <Box
                    sx={{
                      height: "25%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      // paddingRight: "15px",
                    }}
                  >
                    {/* Name and Details */}
                    <Box>
                      <Typography variant="h5">
                        {currentUser.firstName} {currentUser.lastName}
                      </Typography>
                      <Typography variant="subtitle1" color="lightgray">
                        {currentUser.email}
                      </Typography>
                    </Box>

                    {/* Edit Button */}
                    <Button
                      variant="outlined"
                      color="inherit"
                      sx={{
                        color: "#fff",
                        borderColor: "#fff",
                        width: "40px", // Set desired width
                        height: "40px", // Set desired height
                        padding: 0, // Remove padding around the icon
                        minWidth: "40px", // Ensure minimum width for the button
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </Box>
                </Box>

                {/* Main Content Below */}
                <Grid container spacing={4} marginTop={"5px"}>
                  {/* Education and Experience */}
                  <Grid item xs={12} md={9}>
                    <Box>
                      {/* Education Section */}
                      <Box sx={{ mb: 4 }}>
                        <Typography variant="h6">Education</Typography>
                        <Card
                          sx={{
                            mt: 1,
                            border: "2px solid #00000014",
                            borderRadius: "15px",
                          }}
                          elevation={0}
                        >
                          <CardContent sx={{ textAlign: "center" }}>
                            <InfoIcon fontSize="large" />
                            <Typography variant="body2" sx={{ mt: 2 }}>
                              Please, fill in your study information.
                            </Typography>
                            <IconButton sx={{ mt: 2 }}>
                              <AddIcon />
                            </IconButton>
                          </CardContent>
                        </Card>
                      </Box>

                      {/* Experience Section */}
                      <Box>
                        <Typography variant="h6">Experience</Typography>
                        <Card
                          sx={{
                            mt: 1,
                            border: "2px solid #00000014",
                            borderRadius: "15px",
                          }}
                          elevation={0}
                        >
                          <CardContent sx={{ textAlign: "center" }}>
                            <InfoIcon fontSize="large" />
                            <Typography variant="body2" sx={{ mt: 2 }}>
                              Please, fill in your experience information.
                            </Typography>
                            <IconButton sx={{ mt: 2 }}>
                              <AddIcon />
                            </IconButton>
                          </CardContent>
                        </Card>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Sidebar - Upcoming Consultations */}
                  <Grid item xs={12} md={3}>
                    <Card
                      sx={{
                        border: "2px solid #00000014",
                        borderRadius: "15px",
                      }}
                      elevation={0}
                    >
                      <CardContent>
                        <Typography variant="h6">
                          Upcoming consultations
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          We will let notify you.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfilePage;
