import React from "react";
import { 
  Grid, 
  Typography,
  Box,
  CssBaseline,
} from "@mui/material";
import Sidebar from "./Sidebar";

const About = () => {
  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid item sx={{ 
            marginLeft: { xs: 0, md: '240px' },
            width: { xs: '100%', md: 'calc(100% - 240px)',xl: "calc(100% - 450px)" }
          }}>
          <Box sx={{ padding: "20px",}}>
            <div style={{ padding: "20px" }}>
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  About Moinduel
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Revolutionizing math learning through competition and AI.
                </Typography>
              </Box>

              <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Our Vision
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  At Moinduel, we're transforming the way students learn mathematics. By combining the natural human drive for competition with advanced AI technology, we're creating an engaging learning experience that makes math both challenging and enjoyable.
                </Typography>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  What Makes Us Different
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary">• Real-time 1vs1 math duels</Typography>
                  <Typography variant="body2" color="text.secondary">• AI-generated questions tailored to your level</Typography>
                  <Typography variant="body2" color="text.secondary">• Comprehensive solution explanations</Typography>
                  <Typography variant="body2" color="text.secondary">• Dynamic difficulty adjustment</Typography>
                  <Typography variant="body2" color="text.secondary">• Time-pressure challenges that build real-world skills</Typography>
                </Box>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Our Approach
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary">• Combine competitive gaming with serious math learning</Typography>
                  <Typography variant="body2" color="text.secondary">• Use AI to create personalized learning experiences</Typography>
                  <Typography variant="body2" color="text.secondary">• Cover topics from basic arithmetic to SAT-level problems</Typography>
                  <Typography variant="body2" color="text.secondary">• Provide instant feedback and detailed solutions</Typography>
                  <Typography variant="body2" color="text.secondary">• Foster healthy competition among peers</Typography>
                </Box>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Problem We're Solving
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Traditional math learning platforms often focus solely on leaderboards or individual practice. We believe this misses a crucial opportunity to harness the motivational power of real-time competition. By combining competitive duels with comprehensive learning tools, we're creating an environment where students are excited to practice and improve their math skills.
                </Typography>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Our Solution
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary">• Engaging 1vs1 math duels that make learning exciting</Typography>
                  <Typography variant="body2" color="text.secondary">• Smart matchmaking to ensure fair competitions</Typography>
                  <Typography variant="body2" color="text.secondary">• AI-powered question generation for endless practice</Typography>
                  <Typography variant="body2" color="text.secondary">• Detailed explanations to reinforce learning</Typography>
                  <Typography variant="body2" color="text.secondary">• Progress tracking and skill development insights</Typography>
                </Box>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Join Our Community
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Whether you're preparing for exams, looking to improve your math skills, or simply enjoy a good challenge, Moinduel offers a unique platform where competition meets learning. Join us in revolutionizing math education.
                </Typography>

                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  Contact Us
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Have questions or suggestions? Email us at infomoinduel@gmail.com
                </Typography>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default About;